<template>
  <div class="product-recommend clearfix">
    <div
      class="item"
      v-for="product in content"
      :key="product.ProductNumber"
      @click="actionSelected(product)"
    >
      <div class="product">
        <!-- <div class="photo">
          <img :src="product.ProductPhoto | splitFirstItem | imageSrc" />
        </div> -->
        <van-image class="shopImg" :src="imgUrlFilter(product.ProductPhoto)">
          <template v-slot:error>
            <img class="ImgIcon" src="~assets/img/other/empty.gif" />
          </template>
        </van-image>
        <div class="content">
          <div class="title">
            <div class="discount" v-if="product.IsCouponProduct">
              {{ $t.discount }}
            </div>
            <span class="name">{{ product.ProductName }}</span>
          </div>
          <div class="detail">
            <p class="price">
              $
              <span>{{ product.MinPrice }}</span>
            </p>
            <!-- <p class="count">{{ product.Sales }} {{ $t.homeList.Sold }}</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Image } from "vant";
Vue.use(Image);

export default {
  name: "ProductRecommend",
  data() {
    return {
      content: [],
    };
  },
  created() {
    this.request();
  },
  methods: {
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value.split("|")[0]);
    },
    request() {
      this.$commonMethod.showLoading();
      this.$api.product
        .recommendProduct()
        .then((res) => {
          this.content = res.data;
          this.$commonMethod.hideLoading();
        })
        .catch((err) => {
          this.$commonMethod.hideLoading();
          this.$commonMethod.showToast({
            type: "error",
            titleMsg: err.message,
          });
        });
    },
    actionSelected(product) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "details",
        name: "Details",
        query: [
          {
            name: "ProductNumber",
            value: product.ProductNumber,
          },
          {
            name: "ShopId",
            value: product.ShopId,
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/publicStyle";
@import "~assets/scss/var";
.product-recommend {
  padding: 15px 10px 5px;

  .item {
    box-sizing: border-box;
    float: left;
    width: 50%;
    padding: 0 5px;
    margin-bottom: 10px;

    .product {
      border-radius: 10px;
      overflow: hidden;
      background-color: white;
      box-shadow: 0px 2.5px 6.5px 1px rgb(0 0 0 / 10%);
      .shopImg {
        width: 100%;
        height: 44.4vw;
        object-fit: cover;
        -webkit-object-fit: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        .ImgIcon {
          width: 100%;
          height: 100%;
          object-fit: cover;
          -webkit-object-fit: cover;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
        ::v-deep .van-image__img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          -webkit-object-fit: cover;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
        ::v-deep .van-image__error {
          width: 100%;
          height: 100%;
          object-fit: cover;
          -webkit-object-fit: cover;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
      .content {
        padding: 11px 10px 11px;

        .title {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
          text-overflow: ellipsis;
          white-space: normal;
          color: #000000;
          font-size: 12px;
          font-family: Helvetica;
          font-weight: 400;
          height: 32px;
          .discount {
            vertical-align: middle;
            display: inline-block;
            background: red;
            color: white;
            padding: 0px 2px;
            border-radius: 2px;
            font-size: 12px;
            margin-right: 5px;
          }
          .name {
            vertical-align: middle;
            display: inline-block;
          }
        }

        .detail {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          margin-top: 11px;

          .price {
            color: $-color-red;
            font-weight: bold;
            margin: 0;
            font-size: 14px;

            span {
              font-size: 18px;
              line-height: 18px;
              margin-left: 4px;
            }
          }

          .count {
            font-size: 14px;
            color: $-color-light-gray;
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
