<template>
  <div class="pay-completion">
    <div class="pay" :class="{ error: !isSuccess }">
      <div class="state-box">
        <img
          src="~assets/img/order/icon_done_right.png"
          class="icon"
          v-if="isSuccess"
        />
        <img src="~assets/img/order/icon_done_wrong.png" class="icon" v-else />
        <!-- <p class="title">{{ isSuccess ? $t.pay.success : $t.pay.failure }}</p> -->
        <p class="title">{{ isSuccess ? $t.pay.success : errorMsg }}</p>
      </div>
      <p class="error-message" v-if="!isSuccess">{{ errMsg }}</p>
      <div class="actions">
        <div class="btn" v-if="isSuccess" @click="actionDetail">
          {{ $t.pay.detail }}
        </div>
        <div class="btn" @click="backHome">{{ $t.pay.backHome }}</div>
      </div>
    </div>
    <product-recommend></product-recommend>
  </div>
</template>

<script>
import ProductRecommend from "@components/common/ProductRecommend.vue";

export default {
  name: "PayCompletion",
  components: {
    ProductRecommend,
  },
  data() {
    return {};
  },
  computed: {
    queryParams() {
      return this.$route.query;
      //return { status: "01" };
      //return { status: "02", message: "密码不对" };
      // 测试支付成功参数
      // return {
      //   refNo: '20200103172842A100704',
      //   amount: '48736.14',
      //   orderNo: '20200103072497',
      //   transTime: '104501',
      //   failureReason: 'MSG0170',
      //   transDate: '20200104',
      //   mid: '180000000100513',
      //   currency: 'USD',
      //   merchantOrderId: '1578072497ldkJYN',
      //   status: '01',
      //   sign: '061029A43AC498BCE7873AA5BC550ADDCA065EF06495424E4E8F99A66D9B217C'
      // }
    },
    isSuccess() {
      return this.queryParams.status === "01";
    },
    errMsg() {
      return this.queryParams.message;
    },
    orderNumber() {
      return this.$route.query.orderNo;
    },
    errorMsg() {
      return this.$route.query.message
        ? this.$route.query.message
        : this.$t.pay.failure;
    },
  },
  created() {
    //this.request()
  },
  methods: {
    request() {
      this.$commonMethod.showLoading();
      this.$api.order
        .payBack(this.queryqueryParams)
        .then((res) => {
          this.orderNumber = res.data;
          this.$commonMethod.hideLoading();
        })
        .catch((err) => {
          this.$commonMethod.hideLoading();
          this.errMsg = err.message;
          // this.$commonMethod.showToast({
          //   type: 'error',
          //   titleMsg: err.message
          // })
        });
    },
    actionDetail() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerReplace, {
        isParams: true,
        path: "orders",
        name: "orders",
        query: [
          {
            name: "state",
            value: "20",
          },
        ],
      });
    },
    backHome() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.backHome);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/var";
.pay-completion {
  .pay {
    background: linear-gradient(left, #71d283, #01aaa3);
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.error {
      background-color: #fd787a;
      height: 180px;

      .actions {
        margin-top: 15px;
      }
    }

    .state-box {
      display: flex;
      align-items: center;

      .icon {
        width: 24px;
        height: 24px;
      }

      .title {
        margin: 0 0 0 10px;
        color: white;
        font-size: 20px;
        font-weight: bold;
      }
    }

    .error-message {
      color: #fffefe;
      font-size: 12px;
      margin: 10px 0 0 0;
    }

    .actions {
      display: flex;
      margin-top: 30px;

      .btn {
        height: 30px;
        width: 110px;
        border-radius: 15px;
        border: 0.5px solid white;
        color: #fffeee;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;

        & + .btn {
          margin-left: 12px;
        }
      }
    }
  }
}
</style>
